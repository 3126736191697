<template>
    <div>
        <div class="text-4xl font-medium mb-6">Tools - Item Measurement</div>
        <div>
            <div class="text-2xl font-medium mb-2">Mode</div>
            <SelectButton v-model="mode" :options="modeOptions" />
        </div>

        <div v-if="mode == 'Retrieve'" class="flex align-items-start gap-3 mt-5 flex-column">
            <Button label="Paste from Clipboard" icon="pi pi-clipboard" @click="pasteFromClipboard"
                :loading="isLoading.paste"> </Button>
            <Button severity="success" label="Retrieve Item Code From Database" icon="pi pi-cloud-download"
                @click="retrieveItemCodeFromDatabase" :disabled="itemData.length <= 0"> </Button>
            <Button severity="success" label="Retrieve Measurements From Database" icon="pi pi-cloud-download"
                @click="retrieveMeasurementsFromDatabase" :disabled="itemData.length <= 0"> </Button>
            <Button label="Copy to Clipboard" icon="pi pi-copy" @click="copyToClipboard"
                :disabled="itemData.length <= 0">
            </Button>
        </div>

        <div v-if="mode == 'Save'" class="flex align-items-start gap-3 mt-5 flex-column">
            <Button label="Paste from Clipboard" icon="pi pi-clipboard" @click="pasteFromClipboard"
                :loading="isLoading.paste"> </Button>
            <Button severity="success" label="Retrieve Item Code From Database" icon="pi pi-cloud-download"
                @click="retrieveItemCodeFromDatabase" :disabled="itemData.length <= 0"> </Button>
            <Button severity="success" label="Save to Database" icon="pi pi-save" @click="saveToDatabase"
                :loading="isLoading.saveToDatabase" :disabled="itemData.length <= 0"> </Button>
        </div>

        <div>
            <div v-if="missingColumns.length > 0" class="mt-5">
                <div class="text-2xl font-medium mb-2">Issues:</div>
                <div v-for="missingColumn in missingColumns" :key="missingColumn" class="text-lg"> Missing Column: {{
                    missingColumn }} <Button label="Fix" @click="fixMissingColumn(missingColumn)"></Button> </div>
            </div>
            <div>
            </div>
            <DataTable :value="itemData" tableStyle="min-width: 50rem" class="mt-5"
                :rowClass="({ excludeSelect, excludeForce }) => (excludeSelect === true || excludeForce === true) && mode === 'Save' ? 'bg-red-100' : null"
                scrollable scrollHeight="calc(100vh - 500px)">
                <Column field="itemCode" header="**Code" class="font-bold"></Column>
                <Column field="description" header="Description"></Column>
                <Column field="quantity" header="Quantity"></Column>
                <Column field="price" header="Price"></Column>
                <Column field="L" header="**L" class="font-bold"></Column>
                <Column field="W" header="**W" class="font-bold"></Column>
                <Column field="H" header="**H" class="font-bold"></Column>
                <Column field="qty_per_carton" header="**Qty Per Ctn" class="font-bold"></Column>
                <Column field="cm3_per_qty" header="Measurement (cm3/item)">
                    <template #body="slotProps">
                        {{ slotProps.data.cm3_per_qty || "" }}
                    </template>
                </Column>

                <Column field="cm3_per_price" header="CM3 / Price Ratio">
                    <template #body="slotProps">
                        {{ slotProps.data.cm3_per_price || "" }}
                    </template>
                </Column>

                <Column header="Actions" :sortable="false" v-if="mode == 'Save'">
                    <template #body="{ data }">
                        <div class="flex gap-3">
                            <Button v-if="!data.excludeSelect && !data.excludeForce" @click="exclude(data)"
                                label="Exclude" raised style="height: 30px;" severity="danger" />

                            <Button v-if="data.excludeSelect" @click="include(data)" label="Include" raised
                                style="height: 30px;" severity="success" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>


    <!-- Show Dialog Select Missing Columns -->
    <Dialog v-model:visible="dialogMissingColumnFix.show" modal header="Select missing column">
        <div class="text-lg font-medium mb-2">Select missing column for {{ dialogMissingColumnFix.column }} </div>
        <DataTable :value="dialogMissingColumnFix.itemData" tableStyle="min-width: 50rem" scrollable
            scrollHeight="65vh">
            <Column v-for="col of dialogMissingColumnFix.availableColumns" :key="col.field" :field="col.field"
                :header="col.header">
                <template #body="{ data }">
                    <div v-if="data.isAction">
                        <Button @click="fixSelectedColumn(col.field)" label="Select"></Button>
                    </div>
                    <div v-else>
                        {{ data[col.field] }}
                    </div>
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue'
import axios from "axios";
import { useToast } from 'primevue/usetoast';
const toast = useToast();

const isLoading = ref({
    paste: false,
    saveToDatabase: false,
})

const itemData = ref([]);

const headerMap = {
    "货号": "itemCode",
    "itemcode": "itemCode",
    "code": "itemCode",
    "barcode": "itemCode",
    "型号": "itemCode",

    "参数": "description",
    "description": "description",

    "数量": "quantity",
    "qty": "quantity",
    "quantity": "quantity",

    "price": "price",
    "价格": "price",
    "单价": "price",

    "l": "L",
    "长": "L",

    "w": "W",
    "宽": "W",

    "h": "H",
    "高": "H",

    "装箱数": "qty_per_carton",
    "装件数": "qty_per_carton",
    "装箱量": "qty_per_carton",
    "qtypercarton": "qty_per_carton",
    "quantitypercarton": "qty_per_carton",
    "qtyperctn": "qty_per_carton",
    "quantityperctn": "qty_per_carton",

    "measurement": "measurement",
    "箱体积": "measurement",
};

const modeOptions = ref(['Retrieve', 'Save']);
const mode = ref('');

const missingColumns = ref([]);

const pasteFromClipboard = async () => {
    isLoading.value.paste = true;
    try {
        const text = await navigator.clipboard.readText();
        itemData.value = parseExcelData(text);
    } catch (err) {
        console.error("Failed to read clipboard:", err);
    }
    isLoading.value.paste = false;
}

const parseExcelData = (text) => {
    const rows = text.split("\n").map(row => row.split("\t"));

    // Normalize headers, remove spaces, underscores and convert to lowercase
    let headers = rows.shift()?.map(header => header.replace(/[\s_]/g, "").toLowerCase()) || [];
    
    headers = headers.map(header => headerMap[header.trim()] || header);
    // Iterate headers, if there's any empty header, replace with "unkown_header_1", "unknown_header_2", etc.
    headers = headers.map((header, index) => header || `unknown_header_${index + 1}`);

    // Identify missing columns once
    const requiredColumns = ["itemCode", "description", "quantity", "L", "W", "H", "qty_per_carton"];
    const optionalColumns = ["price"];
    missingColumns.value = requiredColumns.filter(col => !headers.includes(col));

    const measurementIndex = headers.indexOf("measurement");
    return rows.map(row => {
        let obj = {};

        headers.forEach((header, index) => {
            let value = (row[index] || "").trim();
            if (measurementIndex !== -1 && header === "measurement" && index >= measurementIndex && index <= measurementIndex + 2) {
                // Only assign if L, W, H are missing
                obj["L"] = obj["L"] || row[measurementIndex] || "";
                obj["W"] = obj["W"] || row[measurementIndex + 1] || "";
                obj["H"] = obj["H"] || row[measurementIndex + 2] || "";
            } else {
                obj[header] = value;
            }
        });

        // If LWH and qty_per_carton exists, calculate measurement
        if (obj["L"] && obj["W"] && obj["H"] && obj["qty_per_carton"]) {
            obj["cm3_per_qty"] = (obj["L"] * obj["W"] * obj["H"] / obj["qty_per_carton"]).toFixed(0);
        }

        // Check if cm3_per_qty and qty_per_carton exists, if yes then calculate ratio of cm3_per_qty and price
        if (obj["cm3_per_qty"] && obj["price"]) {
            obj["cm3_per_price"] = (obj["cm3_per_qty"] / obj["price"]).toFixed(0);
        }

        // If itemCode is not 6 digits of number, clear it
        if (!/^\d{6}$/.test(obj["itemCode"])) {
            obj["itemCode"] = "";
        }

        if (obj["itemCode"] == "" || obj["L"] == "" || obj["W"] == "" || obj["H"] == "" || obj["qty_per_carton"] == "") {
            obj.excludeForce = true;
        }
        return obj;
    });
};

const processItemDataExclude = () => {
    // Check all fields and update excludeForce
    itemData.value = itemData.value.map(item => {
        if (item["itemCode"] == "" || item["L"] == "" || item["W"] == "" || item["H"] == "" || item["qty_per_carton"] == "") {
            item.excludeForce = true;
        }
        else if(item.excludeForce && item["itemCode"] != "" && item["L"] != "" && item["W"] != "" && item["H"] != "" && item["qty_per_carton"] != "") {
            item.excludeForce = false;
        }
        return item;
    });
}

const copyToClipboard = (text) => {
    // Extract only L, W, H columns, even if some rows are empty
    const measurements = itemData.value.map(item =>
        [item.itemCode || "", item.L || "", item.W || "", item.H || "", item.qty_per_carton].join("\t")
    ).join("\n");

    const textToCopy = "Item Code\tL\tW\tH\tQty Per Carton\n" + measurements;

    navigator.clipboard.writeText(textToCopy).then(() => {
        toast.add({
            severity: "success",
            summary: "Success",
            detail: "Measurements copied to clipboard! You can paste them into Excel.",
            life: 2000
        });
    }).catch(err => {
        console.error("Failed to copy text: ", err);
    });
}

const exclude = (item) => {
    item.excludeSelect = true;
}

const include = (item) => {
    item.excludeSelect = false;
}

const dialogMissingColumnFix = ref({
    show: false,
    column: null
});

const fixMissingColumn = (column) => {
    dialogMissingColumnFix.value.column = column;

    // Gett eatch columns from itemData as array of object, with "Field" and "Header"
    dialogMissingColumnFix.value.availableColumns = Object.keys(itemData.value[0]).map(field => ({
        field,
        header: field
    }));

    dialogMissingColumnFix.value.itemData = [...itemData.value];
    // Add an empty row to the start of itemData
    dialogMissingColumnFix.value.itemData.unshift({});
    dialogMissingColumnFix.value.itemData[0].isAction = true;
    dialogMissingColumnFix.value.show = true;
}

const fixSelectedColumn = (field) => {
    const column = dialogMissingColumnFix.value.column;


    itemData.value = itemData.value.map(item => {
        item[column] = item[field];
        return item;
    });

    missingColumns.value = missingColumns.value.filter(col => col !== column);
    dialogMissingColumnFix.value.show = false;
}

const saveToDatabase = async () => {
    isLoading.value.saveToDatabase = true;
    const convertedData = itemData.value.map(item => {
        return {
            item_code: item.itemCode,
            qty_per_carton: item.qty_per_carton,
            length: item.L,
            width: item.W,
            height: item.H
        }
    });

    // Remove rows for which L, W, H, qty_per_carton or item_code is missing
    const filteredData = convertedData.filter(item => item.item_code && item.length && item.width && item.height && item.qty_per_carton);

    await axios({
        method: "POST",
        url: "/items/measurements",
        data: {
            measurements: filteredData
        },
    }).then(
        (result) => {
            if (result.status == 201) {
                console.log("Saved");

                toast.add({
                    severity: "success",
                    summary: "Success",
                    detail: "Saved to database",
                    life: 2000
                });
            }
        },
        (error) => {
            toast.add({
                severity: "error",
                summary: "Error",
                detail: error.response.data.message,
                life: 2000
            });
            console.log(error.response);
        })
    isLoading.value.saveToDatabase = false;
}

const retrieveMeasurementsFromDatabase = async () => {
    isLoading.value.item = true;
    await axios({
        method: "POST",
        url: "items/get-measurements",
        data: {
            item_codes: itemData.value.map(item => item.itemCode)
        }
    }).then(
        (result) => {
            // itemData.value = result.data;
            const retrievedMeasurements = result.data.measurements;

            // Loop through each itemData, and update the measurement to itemData if exist
            // RetrievedMeasurements is in the form obbjeect whereby key is item_code
            itemData.value = itemData.value.map(item => {
                const retrievedMeasurement = retrievedMeasurements[item.itemCode];
                if (retrievedMeasurement) {
                    item.L = retrievedMeasurement.length;
                    item.W = retrievedMeasurement.width;
                    item.H = retrievedMeasurement.height;
                    item.qty_per_carton = retrievedMeasurement.qty_per_carton;
                }
                return item;
            });

        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

const retrieveItemCodeFromDatabase = async () => {
    isLoading.value.item = true;
    await axios({
        method: "POST",
        url: "items/get-item-coded-from-description",
        data: {
            descriptions: itemData.value.map(item => item.description)
        }
    }).then(
        (result) => {
            // itemData.value = result.data;
            const retrievedItems = result.data.items;

            // Loop through each itemData, and update the measurement to itemData if exist
            // RetrievedMeasurements is in the form obbjeect whereby key is item_code
            itemData.value = itemData.value.map(item => {
                const retrievedItem = retrievedItems[item.description];
                if (retrievedItem) {
                    item.itemCode = retrievedItem;
                }
                return item;
            });

            processItemDataExclude();
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}
</script>