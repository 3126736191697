<template>
    <div class="flex gap-3">
        <div class="text-4xl font-medium">Teams</div>
        <Button label="New" @click="showAddNewTeamDialog"></Button>
    </div>

    <!-- Data Table -->
    <DataTable ref="dt" :value="teams" responsiveLayout="scroll" class="mt-6 p-datatable-sm" :loading="isLoading"
        sortMode="multiple" scrollable dataKey="id" scrollHeight="750px" size="small" var="line">

        <Column field="name" header="Name" :sortable="true" headerStyle="width:15rem"> </Column>
        <Column field="description" header="Description" :sortable="true"> </Column>
        <Column field="team_members_count" header="Team Members Count" :sortable="true"> </Column>
        <Column header="Actions" :sortable="false" headerStyle="width:25rem">
            <template #body="{ data }">
                <div class="flex gap-3">
                    <Button @click="showModifyRefillGroupDialog(data)" style="height: 30px;" label="Modify"
                        severity="secondary" raised />
                    <router-link :to="'/teams/' + data.id">
                        <Button label="View" raised style="height: 30px;" />
                    </router-link>
                </div>
            </template>
        </Column>
    </DataTable>


    <!-- Show Add New Dialog -->
    <Dialog v-model:visible="dialogAddNewTeam.show" modal header="Add New Team" :style="{ width: '45rem' }">

        <div class="mt-2 mb-2 text-lg font-medium">Team Name</div>
        <InputText v-model="dialogAddNewTeam.name" type="text" />

        <div class="mt-3 mb-2 text-lg font-medium">Team Description</div>
        <InputText v-model="dialogAddNewTeam.description" type="text" class="mb-4" />

        <!-- Error message -->
        <div v-if="dialogAddNewTeam.errorMessage" class="mt-2 text-red-600">{{
            dialogAddNewTeam.errorMessage
            }}</div>

        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary" @click="dialogAddNewTeam.show = false"
                :loading="dialogAddNewTeam.isLoading" />
            <Button label="Save" class="p-button-raised" @click="savedialogAddNewTeam"
                :loading="dialogAddNewTeam.isLoading" />
        </div>
    </Dialog>

    <!-- Show Modify Dialog -->
    <Dialog v-model:visible="dialogModifyTeam.show" modal header="Modify Team" :style="{ width: '45rem' }">
        <div class="mb-4">
            <div class="mt-2 mb-2 text-lg font-medium">Team Name</div>
            <InputText v-model="dialogModifyTeam.name" type="text" />

            <div class="mt-3 mb-2 text-lg font-medium">Team Description</div>
            <InputText v-model="dialogModifyTeam.description" type="text" />

        </div>

        <!-- Error message -->
        <div v-if="dialogModifyTeam.errorMessage" class="mt-2 text-red-600">{{
            dialogModifyTeam.errorMessage
            }}</div>

        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary" @click="dialogModifyTeam.show = false"
                :loading="dialogModifyTeam.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveDialogModifyTeam"
                :loading="dialogModifyTeam.isLoading" />
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios';
// import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';

const teams = ref([]);
const isLoading = ref(false);

const getTeams = async () => {
    isLoading.value = true;

    await axios({
        method: "GET",
        url: "teams",
    }).then(
        (result) => {
            teams.value = result.data.teams;
        },
        (error) => {
            isLoading.value = false;
            console.log("ERROR GETTING REFILL GROUPS");
            console.log(error.response.data);
        }
    );
    isLoading.value = false;
}

// --------------- Add New Refill Group Dialog ---------------

const dialogAddNewTeam = ref({
    isLoading: false,
    show: false,
    name: "",
    description: "",
});

const showAddNewTeamDialog = () => {
    dialogAddNewTeam.value = {
        show: true,
        name: "",
        description: "",
        errorMessage: "",
    };
}

const savedialogAddNewTeam = async () => {

    dialogAddNewTeam.value.isLoading = true;
    dialogAddNewTeam.value.errorMessage = "";

    await axios({
        method: "POST",
        url: "teams",
        data: {
            name: dialogAddNewTeam.value.name,
            description: dialogAddNewTeam.value.description,
        },
    }).then(
        () => {
            dialogAddNewTeam.value.show = false;
            getTeams();
        },
        (error) => {
            dialogAddNewTeam.value.isLoading = false;
            dialogAddNewTeam.value.errorMessage = error.response.data.message;
            console.log(error.response.data);
        }
    );

    dialogAddNewTeam.value.isLoading = false;
}

// ---------------------------------------------------------


// --------------- Modify Refill Group Dialog ---------------
const dialogModifyTeam = ref({
    isLoading: false,
    show: false,
    name: "",
    description: "",
    is_active: true,
});

const showModifyRefillGroupDialog = (line) => {
    dialogModifyTeam.value = {
        show: true,
        id: line.id,
        name: line.name,
        description: line.description,
        is_active: line.is_active,
        errorMessage: "",
    };
}

const saveDialogModifyTeam = async () => {

    dialogModifyTeam.value.isLoading = true;
    dialogModifyTeam.value.errorMessage = "";

    await axios({
        method: "PATCH",
        url: "teams/" + dialogModifyTeam.value.id,
        data: {
            name: dialogModifyTeam.value.name,
            description: dialogModifyTeam.value.description
        },
    }).then(
        () => {
            dialogModifyTeam.value.show = false;
            getTeams();
        },
        (error) => {
            dialogModifyTeam.value.errorMessage = error.response.data.message;
            console.log(error.response.data);
        }
    );

    dialogModifyTeam.value.isLoading = false;
}
// -------------------------------------------------------------

getTeams();
</script>

<style scoped></style>