<template>
    <div>
        <div class="flex gap-2">
            <Button @click="printBarcodeClicked()" style="height: 30px;" icon="pi pi-tag" raised severity="success"
                :loading="isLoading" label="Barcode"></Button>
            <SplitButton style="height: 30px;" icon="pi pi-print" raised severity="success" :loading="isLoading"
                :label="refill_group.is_external_warehouse == true ? 'Preview' : 'Print'" @click="printRefillGroup()"
                :model="printMenuItems" />
        </div>

        <Dialog v-model:visible="isVisibleHistoryDialog" modal header="Print History" :style="{ 'min-width': '50rem' }"
            :dismissableMask="true">
            <DataTable :value="historyPrints" tableStyle="min-width: 50rem">
                <Column field="created_at" header="Printed At" sortable>
                    <template #body="{ data }">
                        <div v-if="data.created_at">
                            <div
                                :class="moment().isSame(moment(data.created_at), 'day') ? 'text-green-700 font-medium' : ''">
                                <!-- print as format date - time - from now -->
                                {{ moment(data.created_at).format('DD-MMM-YYYY HH:mm:ss') }} ({{
                                    moment(data.created_at).fromNow() }})
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="item_count" header="No. Of Items" sortable></Column>
                <Column header="Actions" :sortable="false">
                    <template #body="{ data }">
                        <div class="flex gap-3">
                            <Button @click="printHistorical(data.id)" icon="pi pi-print" raised style="height: 30px;" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </Dialog>

        <Dialog v-model:visible="isVisibleBarcodeDialog" modal header="Print Barcode"
            :style="{ 'width': 'calc(100vw - 45px)' }" :dismissableMask="true">
            <DataTable :value="historyPrints" tableStyle="min-width: 50rem">
                <Column field="created_at" header="Printed At" sortable>
                    <template #body="{ data }">
                        <div v-if="data.created_at">
                            <div
                                :class="moment().isSame(moment(data.created_at), 'day') ? 'text-green-700 font-medium' : ''">
                                <!-- print as format date - time - from now -->
                                {{ moment(data.created_at).format('DD-MMM-YYYY HH:mm:ss') }} ({{
                                    moment(data.created_at).fromNow() }})
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="item_count" header="No. Of Items" sortable></Column>
                <Column header="Actions" :sortable="false">
                    <template #body="{ data }">
                        <div class="flex gap-3">
                            <Button @click="selectPrintForBarcode(data)" icon="pi pi-caret-right" raised
                                style="height: 30px;" />
                        </div>
                    </template>
                </Column>
            </DataTable>

            <div v-if="currentBarcodeDialogStep == 2">
                <div class="flex justify-content-between mt-8 mb-3">
                    <div class="text-2xl font-medium">Input barcode print quantity</div>
                    <Button @click="copyXmlToClipboard" icon="pi pi-copy" label="Copy To SQL" />
                </div>
                <ag-grid-vue class="ag-theme-alpine" style="height: calc(100vh - 450px);" :columnDefs="columnDefs.value"
                    :rowData="selectedPrintForBarcode.items" :defaultColDef="defaultColDef" animateRows="true"
                    @grid-ready="onGridReady" :gridOptions="gridOptions" @first-data-rendered="onFirstDataRendered">
                </ag-grid-vue>
            </div>
        </Dialog>

        <Dialog v-model:visible="dialogPreview.show" modal header="Print Preview"
            :style="{ 'width': 'calc(100vw - 45px)', 'max-width': '1520px' }" class="pb-3">
            <DataTable :value="dialogPreview.items" tableStyle="min-width: 50rem" class="mt-5"
                :rowClass="({ excludeSelect, excludeForce }) => (excludeSelect === true || excludeForce === true) && mode === 'Save' ? 'bg-red-100' : null"
                scrollable scrollHeight="calc(100vh - 400px)">
                <Column field="CODE" header="Code"></Column>
                <Column field="DESCRIPTION" header="Description"></Column>
                <Column field="SHELF" header="Shelf"></Column>
                <Column field="BALSQTY" header="Qty (Bal.) "></Column>
                <Column field="refill_qty_estimate" header="Qty (Refill Estimate)"></Column>
                <Column field="qty_per_carton" header="Qty Per Ctn">
                    <template #body="{ data }">
                        <InputNumber @value-change="updateRefillCtn" @input="updateRefillCtn" style="width:8rem;"
                            v-model="data.qty_per_carton" />
                    </template>
                </Column>
                <Column field="refill_qty" header="Refill Qty" class="font-bold text-green-700"></Column>
                <Column field="refill_ctn" header="Refill Ctn"></Column>
                <Column header="Actions">
                    <template #body="{ }">
                        <div class="flex gap-3">
                            Test
                        </div>
                    </template>
                </Column>
            </DataTable>
            <div class="flex justify-content-end w-100">
                <div class="flex gap-3 mt-6 flex-column align-items-end">
                    <div :class="{ 'opacity-0': !checkIfAnyEmptyCtn() }"
                        class="text-red-700 font-medium text-lg transition-ease-in transition-all transition-duration-100">
                        [Warning]
                        Items without "Qty Per Ctn" will be skipped!</div>
                    <Button label="Print" class="p-button-raised" style="height: 45px; width: 22rem;" icon="pi pi-print"
                        @click="printExternalWarehouseRefill" />
                </div>
            </div>
        </Dialog>
    </div>

</template>
<script setup>
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridVue } from "ag-grid-vue3";  // the AG Grid Vue Component
const gridApi = ref(null); // Optional - for accessing Grid's API

import { useToast } from 'primevue/usetoast'
import { ref, watch, reactive, defineProps, defineEmits } from "vue";
import moment from 'moment';
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';

const toast = useToast()

const props = defineProps({
    refill_group: Object
})

const emit = defineEmits(['refillGroupPrinted']);

const isVisibleHistoryDialog = ref(false);
const isVisibleBarcodeDialog = ref(false);


const currentBarcodeDialogStep = ref(1);
const selectedPrintForBarcode = ref(null);

const historyPrints = ref([]);

const isLoading = ref(false);

const route = useRoute();

const printMenuItems = [
    {
        icon: 'pi pi-history',
        label: 'Print History',
        command: () => {
            printHistoryClicked();
        }
    },
    {
        separator: true
    }
];
const printHistoryClicked = async () => {
    // Get today's refill group print
    await axios({
        method: "GET",
        url: "refill-groups/" + props.refill_group.id + "/print-history",
    }).then((response) => {
        const historyPrintsFromBackend = response.data.refill_group_prints;

        const printsToShow = [];

        // Iterate each and append item_count
        historyPrintsFromBackend.forEach((print) => {
            print.item_count = print.items.length;

            if (print.item_count > 0) {
                printsToShow.push(print);
            }
        });

        historyPrints.value = printsToShow;
    });

    isVisibleHistoryDialog.value = true;
}
const printBarcodeClicked = async () => {
    // Get today's refill group print
    await axios({
        method: "GET",
        url: "refill-groups/" + props.refill_group.id + "/print-history",
    }).then((response) => {
        const historyPrintsFromBackend = response.data.refill_group_prints;

        const printsToShow = [];

        // Iterate each and append item_count
        historyPrintsFromBackend.forEach((print) => {
            print.item_count = print.items.length;

            if (print.item_count > 0) {
                printsToShow.push(print);
            }
        });

        historyPrints.value = printsToShow;
    });

    selectedPrintForBarcode.value = null;
    currentBarcodeDialogStep.value = 1;
    isVisibleBarcodeDialog.value = true;
}

const selectPrintForBarcode = (selectedPrint) => {
    selectedPrintForBarcode.value = selectedPrint;
    currentBarcodeDialogStep.value = 2;

    // update quantity_to_print to quantity_sold
    selectedPrintForBarcode.value.items.forEach((item) => {
        item.quantity_to_print = item.quantity_sold;
    });
}


// ---- Preview Start ---- //
const dialogPreview = ref({
    show: false,
    items: []
})

const updateRefillCtn = () => {
    dialogPreview.value.items.forEach((item) => {
        if (item.qty_per_carton == null || item.qty_per_carton <= 0) {
            item.refill_ctn = 0;
            item.refill_qty = 0;
            return;
        }

        item.refill_ctn = Math.floor(item.refill_qty_estimate / item.qty_per_carton);
        item.refill_qty = item.refill_ctn * item.qty_per_carton;
    });
}

const checkIfAnyEmptyCtn = () =>
    dialogPreview.value.items.some(item => item.qty_per_carton == null);

const printExternalWarehouseRefill = async () => {
    isLoading.value = true;


    // Compare items with initialItems, the qty_per_carton might be changed
    let items = dialogPreview.value.items;
    let initialItems = dialogPreview.value.initialItems;

    let itemsToSend = [];
    items.forEach((item, index) => {
        if (item.qty_per_carton != initialItems[index].qty_per_carton) {
            itemsToSend.push({
                item_code: item.CODE,
                qty_per_carton: item.qty_per_carton
            });
        }
    });

    let url = "refill-groups/" + props.refill_group.id + "/print-external-warehouse";
    console.log(url);
    // Get Base64 pdf data
    await axios({
        method: "POST",
        url: url,
        responseType: "blob",
        data: {
            updated_items: itemsToSend
        },
    }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);

        // Print the pdf without opening new tab
        const iframe = document.createElement("iframe");
        iframe.style.display = "none"
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();

        emit('refillGroupPrinted', props.refill_group.id);
    });

    dialogPreview.value.show = false;
    isLoading.value = false;
}
// ---- Preview End ---- //

const printRefillGroup = async () => {
    isLoading.value = true;

    if (props.refill_group.is_external_warehouse) {
        let url = "refill-groups/" + props.refill_group.id + "/print?preview=true";

        // Get Base64 pdf data
        await axios({
            method: "GET",
            url: url,
        }).then((response) => {
            dialogPreview.value.initialItems = JSON.parse(JSON.stringify(response.data.items));
            dialogPreview.value.items = response.data.items;
            dialogPreview.value.show = true;
            updateRefillCtn();
        });
    }
    else {
        let url = "refill-groups/" + props.refill_group.id + "/print";

        // Get Base64 pdf data
        await axios({
            method: "GET",
            url: url,
            responseType: "blob",
        }).then((response) => {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            // window.open(fileURL);

            // Print the pdf without opening new tab
            const iframe = document.createElement("iframe");
            iframe.style.display = "none"
            iframe.src = fileURL;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();

            emit('refillGroupPrinted', props.refill_group.id);
        });
    }
    isLoading.value = false;
}

const printHistorical = async (printId) => {
    isLoading.value = true;
    // Get Base64 pdf data
    await axios({
        method: "GET",
        url: "refill-groups/prints/" + printId + "/print",
        responseType: "blob",
    }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);

        // Print the pdf without opening new tab
        const iframe = document.createElement("iframe");
        iframe.style.display = "none"
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    });
    isLoading.value = false;
}

// ----------------------- For ag-grid ------ START ----------------------- //
// Each Column Definition results in one Column.
const columnDefs = reactive({
    value: [
        {
            field: "ST_ITEM_CODE",
            headerName: 'Item Code',
            minWidth: 80,
            maxWidth: 100,
            editable: false,
        },
        {
            field: "item_description",
            headerName: 'Item Description',
            editable: false,
        },
        {
            field: "remark1",
            headerName: 'Remark 1',
            editable: false,
            maxWidth: 250,
        },
        {
            field: "remark2",
            headerName: 'Remark 2',
            editable: false,
            maxWidth: 250,
        },
        {
            field: "refprice",
            headerName: 'Price',
            editable: false,
            maxWidth: 250,
        },
        {
            field: "quantity_to_print",
            headerName: 'Quantity',
            maxWidth: 110
        },

    ],
});

// DefaultColDef sets props common to all Columns
const defaultColDef = {
    sortable: true,
    flex: 1,
    editable: true,
    resizable: true,
    wrapText: false,
    autoHeight: true,
};

// Obtain API from grid's onGridReady event
const onGridReady = (params) => {
    gridApi.value = params.api;
};

const onCellValueChanged = async (editedRow) => {
    // editedRow.node.setDataValue(editedRow.colDef.field, editedRow.data['editedRow.colDef.field']);
    selectedPrintForBarcode.value.items = [...selectedPrintForBarcode.value.items];
};

const gridOptions = ref({
    stopEditingWhenCellsLoseFocus: true,
    undoRedoCellEditing: true, // enables undo / redo
    undoRedoCellEditingLimit: 5, // restricts the number of undo / redo steps to 5
    enableCellChangeFlash: true,  // enables flashing to help see cell changes
    enterNavigatesVerticallyAfterEdit: true,
    suppressScrollOnNewData: true,
    onCellValueChanged: onCellValueChanged,
    singleClickEdit: true,
});

const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
}

const escapeXml = (str) => {
    if (!str) return ''; // Handle null/undefined cases
    return str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;'); // Optional
};

const copyXmlToClipboard = () => {
    let xmlData = `<?xml version="1.0" standalone="yes"?>
<DATAPACKET Version="2.0">
  <METADATA>
    <FIELDS>
      <FIELD attrname="Code" fieldtype="string.uni" WIDTH="60"/>
      <FIELD attrname="Description" fieldtype="string.uni" WIDTH="400"/>
      <FIELD attrname="UOM" fieldtype="string.uni" WIDTH="20"/>
      <FIELD attrname="Barcode" fieldtype="string.uni" WIDTH="100"/>
      <FIELD attrname="RefPrice" fieldtype="fixedFMT" DECIMALS="8" WIDTH="18"/>
      <FIELD attrname="ItemType" fieldtype="string" WIDTH="1"/>
      <FIELD attrname="Remark1" fieldtype="string.uni" WIDTH="400"/>
      <FIELD attrname="Remark2" fieldtype="string.uni" WIDTH="400"/>
      <FIELD attrname="Copies" fieldtype="i4"/>
    </FIELDS>
    <PARAMS/>
  </METADATA>
  <ROWDATA>\n`;

    selectedPrintForBarcode.value.items.forEach(item => {
        if (item.quantity_to_print <= 0) return;
        xmlData += `    <ROW UOM="UNIT"
        Code="${escapeXml(item.ST_ITEM_CODE)}" 
        Barcode="${escapeXml(item.ST_ITEM_CODE)}" 
        Description="${escapeXml(item.item_description)}" 
        RefPrice="${escapeXml(item.refprice)}" 
        Remark1="${escapeXml(item.remark1 || '')}" 
        Remark2="${escapeXml(item.remark2 || '')}" 
        Copies="${item.quantity_to_print}"/>\n`;
    });

    xmlData += `  </ROWDATA>\n</DATAPACKET>`;

    // Copy to clipboard
    navigator.clipboard.writeText(xmlData)
        .then(() => {
            toast.add({ severity: 'success', summary: 'Success', detail: 'XML copied to clipboard', life: 3000 });
        })
        .catch(err => {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to copy XML to clipboard', life: 3000 });
        });
}
</script>