<template>
    <div class="card h-min">
        <div class="flex mb-5 justify-content-between align-items-center">
            <div class="text-xl font-medium ">Item Measurement</div>
            <Button v-if="!isEditing" class="flex align-center  justify-content-center"
                @click="isEditing = true">Edit</Button>

            <div v-else class="flex justify-content-between align-items-center gap-3">
                <Button class="flex align-center  justify-content-center" severity="secondary"
                    @click="cancel">Cancel</Button>
                <Button class="flex align-center  justify-content-center" severity="success" @click="save">Save</Button>
            </div>
        </div>
        <div class="flex flex-column gap-4">
            <div class="">
                <div class="font-semibold mb-1">Volume Per Item (pcs)</div>
                <div class="">
                    {{ getVolume(inputs.measurement.cartonDimensions.length,
                        inputs.measurement.cartonDimensions.width,
                        inputs.measurement.cartonDimensions.height, inputs.measurement.quantityPerCarton)
                    }}
                </div>
            </div>
            <div class="">
                <div class="font-semibold mb-1">Quantity Per Carton</div>
                <InputNumber v-model="inputs.measurement.quantityPerCarton" :disabled="!isEditing" />
            </div>
            <div class="">
                <div class="font-semibold mb-1">Carton Dimensions (CM)</div>
                <div class="flex gap-2 align-items-center">
                    <InputNumber v-model="inputs.measurement.cartonDimensions.length" :disabled="!isEditing"
                        :maxFractionDigits="2" /> x
                    <InputNumber v-model="inputs.measurement.cartonDimensions.width" :disabled="!isEditing"
                        :maxFractionDigits="2" /> x
                    <InputNumber v-model="inputs.measurement.cartonDimensions.height" :disabled="!isEditing"
                        :maxFractionDigits="2" />
                </div>
            </div>

            <div class="">
                <div class="font-semibold mb-1">Carton Weight (KG)</div>
                <div class="flex gap-2 align-items-center">
                    <InputNumber v-model="inputs.measurement.cartonDimensions.weight" :disabled="!isEditing"
                        :maxFractionDigits="2" /> 
                </div>
            </div>
        </div>
        <Message v-if="errorMessage" severity="error" class="mt-2">{{ errorMessage }}</Message>

        <div class="font-semibold mt-5 mb-2">History</div>
        <DataTable :value="measurements" scrollable scrollHeight="300px">
            <Column field="created_at" header="Date">
                <template #body="{ data }">
                    <!-- YYYY-MM-DD HH:MM -->
                    {{ moment(data.created_at).format('YYYY-MM-DD HH:mm') }}
                    
                    </template>
            </Column>

            
            <Column field="carton_length_cm" header="L"></Column>
            <Column field="carton_width_cm" header="W"></Column>
            <Column field="carton_height_cm" header="H"></Column>
            <Column field="qty_per_carton" header="Qty / Ctn"></Column>
            <Column header="Measurement">
                <template #body="{ data }">
                    {{ getVolume(data.carton_length_cm, data.carton_width_cm, data.carton_height_cm,
                        data.qty_per_carton) }}
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';

const isLoading = ref(false);

const route = useRoute();

const props = defineProps({
    itemCode: String,
    initial_measurement: Object,
    measurements: Array
})

const inputs = ref({
    measurement: {
        isEditing: false,
        quantityPerCarton: props.initial_measurement?.qty_per_carton,
        cartonDimensions: {
            length: props.initial_measurement?.carton_length_cm,
            width: props.initial_measurement?.carton_width_cm,
            height: props.initial_measurement?.carton_height_cm,
            weight: props.initial_measurement?.carton_weight_kg
        }
    }
})

const errorMessage = ref("");

const isEditing = ref(false);

const getVolume = (length, width, height, qtyPerCarton) => {
    // Check if null, return null
    if (length == null || width == null || height == null || qtyPerCarton == null) {
        return null
    }

    const volumePerItemCM3 = length * width * height / qtyPerCarton;
    const volumePerItemM3 = volumePerItemCM3 / 1000000;

    // Return volume in thousands separator
    return volumePerItemM3.toLocaleString('en-US', { maximumFractionDigits: 3 }) + ' m³ | ' + volumePerItemCM3.toLocaleString('en-US', { maximumFractionDigits: 0 }) + ' cm³';
}

const cancel = () => {
    isEditing.value = false;
    inputs.value.measurement.quantityPerCarton = props.initial_measurement?.qty_per_carton;
    inputs.value.measurement.cartonDimensions.length = props.initial_measurement?.carton_length_cm;
    inputs.value.measurement.cartonDimensions.width = props.initial_measurement?.carton_width_cm;
    inputs.value.measurement.cartonDimensions.height = props.initial_measurement?.carton_height_cm;
    inputs.value.measurement.cartonDimensions.weight = props.initial_measurement?.carton_weight_kg;
}

const save = async () => {
    errorMessage.value = "";
    isLoading.value = true;
    await axios({
        method: "POST",
        url: "/items/measurements",
        data: {
            measurements:
                [{
                    item_code: route.params.code,
                    qty_per_carton: inputs.value.measurement.quantityPerCarton,
                    length: inputs.value.measurement.cartonDimensions.length,
                    width: inputs.value.measurement.cartonDimensions.width,
                    height: inputs.value.measurement.cartonDimensions.height,
                    weight: inputs.value.measurement.cartonDimensions.weight
                }]
        },
    }).then(
        (result) => {
            if (result.status == 201) {
                isEditing.value = false;
                console.log("Saved");
            }
        },
        (error) => {
            console.log(error.response);
            errorMessage.value = error.response.data.message;
        }
    );
    isLoading.value = false;
}

</script>