<template>
    <router-link :to="'/teams'">
        <Button label="Back to Teams" severity="secondary" icon="pi pi-arrow-left" outlined> </Button>
    </router-link>

    <div class="flex gap-3 mt-3">
        <div class="text-2xl font-medium">Team</div>
    </div>

    <div v-if="team != null" class="card mt-5">
        <div class="text-lg mb-2 font-medium">Team Name</div>
        <div> {{ team.name }}</div>

        <div class="mt-5 mb-2 text-lg font-medium">Team Description</div>
        <div> {{ team.description }}</div>


        <div class="flex mt-5 mb-2 gap-3 align-items-center">
            <div class="text-lg font-medium">Team Members</div>
            <Button @click="showAddMembersDialog()" style="height: 30px;" label="Add Member" raised />
        </div>

        <DataTable ref="dt" :value="team.team_members" responsiveLayout="scroll" class="mt-6 p-datatable-sm"
            :loading="isLoading" sortMode="multiple" scrollable dataKey="id" scrollHeight="650px" size="small"
            var="line">

            <Column field="CODE" header="CODE" :sortable="true" headerStyle="width:15rem"> </Column>
            <Column field="DESCRIPTION" header="DESCRIPTION" :sortable="true"> </Column>
            <Column header="Actions" :sortable="false" headerStyle="width:25rem">
                <template #body="{ data }">
                    <div class="flex gap-3">
                        <Button @click="deleteMember(data)" style="height: 30px;" label="Delete" severity="danger"
                            raised />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>

    <!-- Show Add Member -->
    <Dialog v-model:visible="dialogAddMembers.show" modal header="Add Members" :style="{ width: '45rem' }">

        <DataTable v-model:selection="dialogAddMembers.selectedMembers" :value="agentsWithoutTeam" dataKey="CODE"
            scrollHeight="400px" size="small" responsiveLayout="scroll">
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="CODE" header="Code"></Column>
            <Column field="DESCRIPTION" header="Description"></Column>
        </DataTable>

        <!-- Error message -->
        <div v-if="dialogAddMembers.errorMessage" class="mt-2 text-red-600">{{
            dialogAddMembers.errorMessage
            }}</div>

        <div class="flex mt-4 gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary" @click="dialogAddMembers.show = false"
                :loading="dialogAddMembers.isLoading" />
            <Button @click="dialogAddMembersSave" label="Add Selected" class="p-button-raised"
                :loading="dialogAddMembers.isLoading" />
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
// import moment from 'moment';

const team = ref(null);
const agentsWithoutTeam = ref([]);
const isLoading = ref(false);

const getTeam = async () => {
    isLoading.value = true;

    await axios({
        method: "GET",
        url: "teams/" + route.params.id,
    }).then(
        (result) => {
            team.value = result.data.team;
            agentsWithoutTeam.value = result.data.agentsWithoutTeam;
        },
        (error) => {
            isLoading.value = false;
            console.log("ERROR GETTING TEAM SINGLE");
            console.log(error.response.data);
        }
    );
    isLoading.value = false;
}

//  Dialog Add Members -------------
const dialogAddMembers = ref({
    isLoading: false,
    show: false,
    selectedMembers: [],
    errorMessage: ''
});

const showAddMembersDialog = () => {
    dialogAddMembers.value = {
        isLoading: false,
        show: true,
        selectedMembers: [],
        errorMessage: ''
    }
}

const dialogAddMembersSave = () => {
    dialogAddMembers.value.isLoading = true;
    dialogAddMembers.value.errorMessage = '';

    axios({
        method: "POST",
        url: "teams/" + route.params.id + "/members",
        data: {
            members: dialogAddMembers.value.selectedMembers
        }
    }).then(
        (result) => {
            dialogAddMembers.value.isLoading = false;
            dialogAddMembers.value.show = false;
            getTeam();
        },
        (error) => {
            dialogAddMembers.value.isLoading = false;
            dialogAddMembers.value.errorMessage = error.response.data.message;
        }
    );
}
//  Dialog Add Members END -------------

const deleteMember = (member) => {
    axios({
        method: "DELETE",
        url: "teams/" + route.params.id + "/members",
        data : {
            CODE : member.CODE
        }
    }).then(
        (result) => {
            getTeam();
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

getTeam();
</script>

<style scoped></style>