<template>
    <Button label="Update" @click="showDialog" class="px-3 py-2"></Button>
    <Dialog v-model:visible="show" modal header="Update Claim Status" :style="{ width: '78rem' }">
        <div class="flex flex-column gap-3">
            <div class="w-12">
                <div class="mb-1 text-lg font-medium">Your Name (Agent Name) </div>
                <InputText class="" v-model="finalData.name" type="text" />
            </div>
            <div class="w-12">
                <div class="mb-1 text-lg font-medium">New Status (Current: {{ props.claimData.status }})</div>
                <Dropdown v-model="finalData.status" :options="statusOptions" placeholder="Select a Status"
                    class="w-full md:w-14rem" />
            </div>
            <div class="w-12">
                <div class="mb-1 text-lg font-medium">Reason (Optional) </div>
                <Textarea class="w-12" v-model="finalData.update_status_reason" rows="5"></Textarea>
            </div>
        </div>

        <!-- Error message -->
        <div v-if="finalData.errorMessage" class="mt-2 text-red-600" style="white-space: pre;">{{
            finalData.errorMessage
            }}</div>

        <div class="flex gap-3 w-100 justify-content-end mt-6">
            <Button label="Cancel" class="p-button-raised" severity="secondary" @click="show = false"
                :loading="isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveEditClaim()" :loading="isLoading" :disabled="!checkIfReadyToSave()" />
        </div>
    </Dialog>
</template>



<script setup>
import { convertPrimeVueCalendar } from '../../utils/convertPrimeVueCalendar';
import { ref, onMounted, defineModel, defineProps, defineEmits, nextTick } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, maxValue } from '@vuelidate/validators'
import axios from "axios";

const show = ref(false);
const errorMessage = ref("");
const props = defineProps(['claimData'])

const finalData = ref(JSON.parse(JSON.stringify(props.claimData)));
const statusOptions = ref(['New', 'Testing', 'Claiming', 'Received', 'Pending Pickup', 'Settled', 'Closed']);

// Get current status, then remove it from the options
statusOptions.value.splice(statusOptions.value.indexOf(props.claimData.status), 1);

const emit = defineEmits(['saved'])

// Create copy of claimData

const showDialog = () => {
    show.value = true;
    finalData.value = JSON.parse(JSON.stringify(props.claimData));
    isLoading.value = false;
    errorMessage.value = "";
}

const checkIfReadyToSave = () => {
    // Check if name is empty
    if (finalData.value.name == "") {
        finalData.value.errorMessage = "Name is required";
        return false;
    }

    // Check if status is empty or same as current status
    if (finalData.value.status == "" || finalData.value.status === props.claimData.status) {
        finalData.value.errorMessage = "Please select a different status";
        return false;
    }

    return true;
}

const isLoading = ref(false);
const saveEditClaim = async (name) => {
    isLoading.value = true;
    errorMessage.value = "";

    const data = {
        id: finalData.value.id,
        status: finalData.value.status,
        update_status_reason: finalData.value.update_status_reason,
        edited_by: finalData.value.name,
    };

    await axios({
        method: "PUT",
        url: "claims/status",
        data: data,
    }).then(
        (result) => {
            isLoading.value = false;
            show.value = false;
            emit('saved');
        },
        (error) => {
            isLoading.value = false;
            errorMessage.value = error.response.data.message;
        }
    );
}

</script>


<style scoped></style>